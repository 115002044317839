// src/components/MapSelection.js

import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLocations, unsetLocations } from '../redux/locationSlice';

const libraries = ['places'];

const mapContainerStyle = {
  height: '100vh',
  width: '100%',
};

const center = {
  lat: 23.4241,
  lng: 53.8478,
};

const MapSelection = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC2FlUCcKISyKpukAc0noyLsd1mCJu4Ld0",
    libraries,
  });

  const [pickupLocation, setPickupLocation] = useState(null);
  const [dropoffLocation, setDropoffLocation] = useState(null);
  const [pickupAddress, setPickupAddress] = useState('');
  const [dropoffAddress, setDropoffAddress] = useState('');
  const [pickupCoordinates, setPickupCoordinates] = useState(null);
  const [dropoffCoordinates, setDropoffCoordinates] = useState(null);
  const [directions, setDirections] = useState(null);
  const [distance, setDistance] = useState(null);
  const [showRoute, setShowRoute] = useState(false);
  const [mapKey, setMapKey] = useState(0);
  
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const autocompletePickupRef = useRef(null);
  const autocompleteDropoffRef = useRef(null);
  const pickupInputRef = useRef(null);
  const dropoffInputRef = useRef(null);
  
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  
  useEffect(() => {
    if (!userInfo) {
      // If user info is not present, navigate to login page
      navigate('/login');
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (isLoaded) {
      const autocompletePickup = new window.google.maps.places.Autocomplete(pickupInputRef.current);
      autocompletePickup.setFields(['geometry', 'formatted_address']);
      autocompletePickup.addListener('place_changed', handlePickupPlaceChanged);
      autocompletePickupRef.current = autocompletePickup;

      const autocompleteDropoff = new window.google.maps.places.Autocomplete(dropoffInputRef.current);
      autocompleteDropoff.setFields(['geometry', 'formatted_address']);
      autocompleteDropoff.addListener('place_changed', handleDropoffPlaceChanged);
      autocompleteDropoffRef.current = autocompleteDropoff;
    }
  }, [isLoaded]);

  const handlePickupPlaceChanged = () => {
    const place = autocompletePickupRef.current.getPlace();
    if (place && place.geometry) {
      const position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setPickupLocation(position);
      setPickupCoordinates(position);
      setPickupAddress(place.formatted_address);
      mapRef.current.panTo(position);
      mapRef.current.setZoom(15);
    } else {
      console.log('Invalid pickup location selected.');
    }
  };

  const handleDropoffPlaceChanged = () => {
    const place = autocompleteDropoffRef.current.getPlace();
    if (place && place.geometry) {
      const position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setDropoffLocation(position);
      setDropoffCoordinates(position);
      setDropoffAddress(place.formatted_address);
      if (pickupLocation === null) {
        mapRef.current.panTo(position);
        mapRef.current.setZoom(15);
      }
    } else {
      console.log('Invalid drop-off location selected.');
    }
  };

  const clearLocations = () => {
    setPickupLocation(null);
    setDropoffLocation(null);
    setPickupCoordinates(null);
    setDropoffCoordinates(null);
    setPickupAddress('');
    setDropoffAddress('');
    setDirections(null);
    setDistance(null);
    setShowRoute(false);
    setMapKey((prevKey) => prevKey + 1); // Change the key to force re-render
    pickupInputRef.current.value = ''; // Clear pickup input field
    dropoffInputRef.current.value = ''; // Clear dropoff input field
    dispatch(unsetLocations());
  };

  const handleCalculateRoute = () => {
    if (pickupLocation && dropoffLocation) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: pickupLocation,
          destination: dropoffLocation,
          travelMode: 'DRIVING',
        },
        (result, status) => {
          if (status === 'OK') {
            setDirections(result);
            setShowRoute(true);

            // Calculate bounds of the route
            const bounds = new window.google.maps.LatLngBounds();
            result.routes[0].legs.forEach((leg) => {
              leg.steps.forEach((step) => {
                step.path.forEach((point) => {
                  bounds.extend(point);
                });
              });
            });

            // Fit map to the bounds of the route
            mapRef.current.fitBounds(bounds);

            // Optionally adjust zoom level
            const maxZoom = mapRef.current.maxZoom || 15; // Max zoom level, adjust as needed
            const currentZoom = mapRef.current.getZoom();
            const newZoom = Math.min(currentZoom, maxZoom); // Ensure new zoom doesn't exceed maxZoom
            mapRef.current.setZoom(newZoom);

            // Pan map to center the route towards the bottom
            const mapCenter = mapRef.current.getCenter();
            const offsetLat = (bounds.getNorthEast().lat() - bounds.getSouthWest().lat()) * 0.3; // Adjust as needed
            const newCenter = new window.google.maps.LatLng(mapCenter.lat() - offsetLat, mapCenter.lng());
            mapRef.current.panTo(newCenter);

            // Extract distance from directions result
            const distanceValue = result.routes[0].legs[0].distance.text;
            setDistance(distanceValue);
          } else {
            console.error(`Directions request failed due to ${status}`);
          }
        }
      );
    }
  };

  const handleConfirmLocation = () => {
    const pickup = {
      coordinates: pickupCoordinates,
      address: pickupAddress,
    };
    const dropoff = {
      coordinates: dropoffCoordinates,
      address: dropoffAddress,
    };
    const payload = {
      pickup,
      dropoff,
      distance,
    };
    dispatch(setLocations(payload));
    navigate('/booking-summary'); // Navigate to '/pickup' route
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }

  return (
    <div className="map-selection-container">
      <GoogleMap
        key={mapKey} // Add key prop here
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={7}
        center={center}
        onLoad={(map) => (mapRef.current = map)}
      >
        {pickupLocation && <Marker position={pickupLocation} />}
        {dropoffLocation && <Marker position={dropoffLocation} />}
        {directions && showRoute && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
      <div className="controls">
        <input
          id="pickup-autocomplete"
          ref={pickupInputRef} // Set ref for pickup input
          placeholder="Search pickup location"
          type="text"
          className="input-field"
        />
        {pickupCoordinates && (
          <p className="coordinates">
            {/* Pickup Location: Lat: {pickupCoordinates.lat}, Lng: {pickupCoordinates.lng} */}
          </p>
        )}
        <input
          id="dropoff-autocomplete"
          ref={dropoffInputRef} // Set ref for dropoff input
          placeholder="Search drop-off location"
          type="text"
          className="input-field"
        />
        {dropoffCoordinates && (
          <p className="coordinates">
            {/* Dropoff Location: Lat: {dropoffCoordinates.lat}, Lng: {dropoffCoordinates.lng} */}
          </p>
        )}
        <div className="buttons">
          {pickupLocation && dropoffLocation && !showRoute && (
            <button className="button" onClick={handleCalculateRoute}>Show Route</button>
          )}
          {showRoute && (
            <>
              <button className="button" onClick={() => setShowRoute(false)}>Hide Route</button>
              {distance && (
                <p className="distance">Distance: {distance}</p>
              )}
              <button className="button" onClick={handleConfirmLocation}>Confirm Location</button>
            </>
          )}
          {(pickupLocation || dropoffLocation) && (
            <button className="button" onClick={clearLocations}>Reset Locations</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MapSelection;
