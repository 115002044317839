import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios'; // Import axios for making HTTP requests
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const SummaryPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const userInfo = useSelector((state) => state.user.userInfo);
  const searchCriteria = useSelector((state) => state.search.searchCriteria);
  const selectedCars = useSelector((state) => state.search.selectedCars);
  const pickupLocation = useSelector((state) => state.location.pickup);
  const dropoffLocation = useSelector((state) => state.location.dropoff);
  const distance = useSelector((state) => state.location.distance);

  const [contactName, setContactName] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [chauffeurSelected, setChauffeurSelected] = useState(false);
  const [breakdown, setBreakdown] = useState({});

  const CHAUFFEUR_BASE_PRICE = 150;
  const CHAUFFEUR_EXTRA_HOUR_RATE = 25;

  useEffect(() => {
    if (userInfo && userInfo.name && userInfo.contactNo && userInfo.email) {
      setContactName(userInfo.name);
      setContactNo(userInfo.contactNo);
      setContactEmail(userInfo.email);
    }
  }, [userInfo]);

  useEffect(() => {
    calculateTotalPrice();
  }, [searchCriteria, selectedCars, chauffeurSelected, distance]); // Include distance in dependencies

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setContactName(userInfo.name);
      setContactNo(userInfo.contactNo);
      setContactEmail(userInfo.email);
    } else {
      setContactName('');
      setContactNo('');
      setContactEmail('');
    }
  };

  const handleChauffeurChange = (e) => {
    setChauffeurSelected(e.target.checked);
  };

  const calculateTotalPrice = () => {
    if (!searchCriteria.startDate || !searchCriteria.endDate) return;

    const startDate = dayjs(searchCriteria.startDate);
    const endDate = dayjs(searchCriteria.endDate);
    const totalDays = endDate.diff(startDate, 'day') + 1;

    let total = 0;
    let carBreakdown = {};

    selectedCars.forEach(selectedCar => {
      const car = selectedCar.car;
      const quantity = selectedCar.quantity;

      const months = Math.floor(totalDays / 30);
      const remainingDaysAfterMonths = totalDays % 30;
      const weeks = Math.floor(remainingDaysAfterMonths / 7);
      const days = remainingDaysAfterMonths % 7;

      const carTotal = (months * car.month_rate) + (weeks * car.week_rate) + (days * car.day_rate);
      total += carTotal * quantity;

      carBreakdown[car.name] = {
        months,
        weeks,
        days,
        total: carTotal * quantity
      };
    });

    if (chauffeurSelected) {
      let totalChauffeurPrice = 0;

      for (let i = 0; i < totalDays; i++) {
        const dayHours = 24;
        let dailyChauffeurPrice = CHAUFFEUR_BASE_PRICE;

        if (dayHours > 8) {
          dailyChauffeurPrice += (dayHours - 8) * CHAUFFEUR_EXTRA_HOUR_RATE;
        }

        totalChauffeurPrice += dailyChauffeurPrice;
      }

      total += totalChauffeurPrice;
      carBreakdown['Chauffeur Service'] = {
        days: totalDays,
        total: totalChauffeurPrice
      };
    }

    setBreakdown(carBreakdown);
    setTotalPrice(total);
  };

  const handleConfirmBooking = async () => {
    // Extract numeric value from distance and convert to integer
    if(distance!= null){

    }
const numericDistance = parseInt(distance.replace(/\D/g, ''), 10); // Remove non-digit characters

    const bookingData = {
      userInfo,
      searchCriteria,
      selectedCars,
      pickupLocation: {
        coordinates: pickupLocation.coordinates,
        address: pickupLocation.address
      },
      dropoffLocation: {
        coordinates: dropoffLocation.coordinates,
        address: dropoffLocation.address
      },
      distance: numericDistance, // Use the parsed integer distance
      contactName,
      contactNo,
      contactEmail,
      totalPrice,
      chauffeurSelected,
      breakdown,
      bookingDateTime: dayjs().format('YYYY-MM-DD HH:mm:ss') // Current date and time
    };

    try {
      const response = await axios.post('https://car-backend-ecvl.onrender.com/booking/api/bookings', bookingData);
      console.log('Booking successful:', response.data);
      // Reset state variables after successful booking
      setContactName('');
      setContactNo('');
      setContactEmail('');
      setTotalPrice(0);
      setChauffeurSelected(false);
      setBreakdown({});
      // Navigate to bookings page
      navigate('/bookings');
    } catch (error) {
      console.error('Error booking:', error);
      // Handle booking error
    }
  };

  return (
    <div className="summary-page">
      <h1>Summary Page</h1>

      {searchCriteria && (
        <div className="search-criteria">
          <h2>Search Criteria</h2>
          <p>Car Type: {searchCriteria.carType}</p>
          <p>Start Date: {searchCriteria.startDate}</p>
          <p>End Date: {searchCriteria.endDate}</p>
          <p>Start Time: {searchCriteria.startTime}</p>
          <p>End Time: {searchCriteria.endTime}</p>
        </div>
      )}

      {selectedCars && selectedCars.length > 0 && (
        <div className="selected-cars">
          <h2>Selected Cars</h2>
          <ul>
            {selectedCars.map((selectedCar, index) => (
              <li key={index}>
                <div className="car-item">
                  <img src={selectedCar.car.imageUrl} alt={selectedCar.car.name} />
                  <div className="car-details">
                    <h3>{selectedCar.car.name}</h3>
                    <p>Type: {selectedCar.car.type}</p>
                    <p>Price: ${selectedCar.car.price} per day</p>
                    <p>Seats: {selectedCar.car.seats}</p>
                    <p>Quantity: {selectedCar.quantity}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="location-info">
        <h2>Location Information</h2>
        <div className="pickup-info">
          <h3>Pickup Location</h3>
          <p>Address: {pickupLocation.address}</p>
        </div>

        <div className="dropoff-info">
          <h3>Dropoff Location</h3>
          <p>Address: {dropoffLocation.address}</p>
        </div>
      </div>

      <div className="contact-person-details">
        <h2>Contact Person Details</h2>
        <div className="input-group">
          <label>Name:</label>
          <input
            type="text"
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Contact No:</label>
          <input
            type="text"
            value={contactNo}
            onChange={(e) => setContactNo(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Email:</label>
          <input
            type="email"
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
          />
        </div>
        <div className="checkbox-group">
          <input
            type="checkbox"
            checked={contactName === userInfo.name && contactNo === userInfo.contactNo && contactEmail === userInfo.email}
            onChange={handleCheckboxChange}
          />
          <label>Use same details as user</label>
        </div>
      </div>

      <div className="chauffeur-option">
        <input
          type="checkbox"
          checked={chauffeurSelected}
          onChange={handleChauffeurChange}
        />
        <label>Add Chauffeur Service (${CHAUFFEUR_BASE_PRICE} for 8 hours, $25 per extra hour)</label>
      </div>

      <div className="price-breakdown">
        <h2>Price Breakdown</h2>
        <ul>
          {Object.keys(breakdown).map((key, index) => (
            <li key={index}>
              <p>{key}: ${breakdown[key].total.toFixed(2)}</p>
              {key !== 'Chauffeur Service' && (
                <p>Months: {breakdown[key].months}, Weeks: {breakdown[key].weeks}, Days: {breakdown[key].days}</p>
              )}
              {key === 'Chauffeur Service' && (
                <p>Days: {breakdown[key].days}</p>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className="total-price">
        <h2>Total Price</h2>
        <p>${totalPrice.toFixed(2)}</p>
      </div>

      <div className="confirm-booking">
        <button onClick={handleConfirmBooking}>Confirm Booking</button>
      </div>
    </div>
  );
};

export default SummaryPage;
