import React from 'react';
import Lottie from 'lottie-react';
import animationData from './Animation - 1716663224960.json';

const Loading = () => {
  return (
    <div className="loading-container">
      <Lottie animationData={animationData} className="lottie-animation" />
      <div className="text-animation">
        Gatwick Rent A Car
      </div>
    </div>
  );
};

export default Loading;
