import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSearchCriteria,
  selectSearchCriteria,
  selectSelectedCars,
  setSelectedCars,
  addSelectedCar,
  removeSelectedCar
} from '../redux/searchSlice';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SearchCars = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchCriteria = useSelector(selectSearchCriteria);
  const selectedCars = useSelector(selectSelectedCars);

  const [editMode, setEditMode] = useState(false);
  const [formValues, setFormValues] = useState({
    carType: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: ''
  });

  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);

  // Fetch cars data from API on component mount
  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get('https://car-backend-ecvl.onrender.com/cars'); // Replace with your backend API endpoint
        setCars(response.data);
      } catch (error) {
        console.error('Error fetching cars:', error);
      }
    };

    fetchCars();
  }, []);

  // Filter cars based on selected carType
  useEffect(() => {
    if (formValues.carType) {
      const filtered = cars.filter(car => car.type === formValues.carType);
      setFilteredCars(filtered);
    } else {
      setFilteredCars(cars); // If no carType selected, show all cars
    }
  }, [formValues.carType, cars]);

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Function to save edited criteria
  const handleSave = () => {
    dispatch(setSearchCriteria(formValues));
    setEditMode(false);
    saveSearchCriteriaToLocalStorage(formValues); // Save to localStorage after dispatching
  };

  // Function to handle selecting a car
  const handleSelectCar = (car) => {
    dispatch(addSelectedCar({ car, quantity: 1 }));
  };

  // Function to handle removing a car
  const handleRemoveCar = (car) => {
    dispatch(removeSelectedCar(car._id));
  };

  // Function to save search criteria to localStorage
  const saveSearchCriteriaToLocalStorage = (criteria) => {
    localStorage.setItem('searchCriteria', JSON.stringify(criteria));
  };

  // Function to save selected cars to localStorage
  const saveSelectedCarsToLocalStorage = (selectedCars) => {
    localStorage.setItem('selectedCars', JSON.stringify(selectedCars));
  };

  // Function to load search criteria from localStorage
  const loadSearchCriteriaFromLocalStorage = () => {
    const storedSearchCriteria = localStorage.getItem('searchCriteria');
    if (storedSearchCriteria) {
      const { carType, startDate, endDate, startTime, endTime } = JSON.parse(storedSearchCriteria);
      setFormValues({
        carType: carType || '',
        startDate: startDate || '',
        endDate: endDate || '',
        startTime: startTime || '',
        endTime: endTime || ''
      });
    }
  };

  // Load search criteria and selected cars from localStorage on component mount
  useEffect(() => {
    const loadSelectedCarsFromLocalStorage = () => {
      const storedSelectedCars = localStorage.getItem('selectedCars');
      if (storedSelectedCars) {
        dispatch(setSelectedCars(JSON.parse(storedSelectedCars)));
      }
    };

    loadSearchCriteriaFromLocalStorage();
    loadSelectedCarsFromLocalStorage();
  }, [dispatch]);

  // Effect to save selected cars to localStorage whenever selectedCars changes
  useEffect(() => {
    saveSelectedCarsToLocalStorage(selectedCars);
  }, [selectedCars]);

  // Function to handle "Select Location" action
  const handleSelectLocation = () => {
    // Implement logic to handle navigation to location selection or related action
    console.log('Select Location action');
    navigate('/maps/select-location');
    // Example: Navigate to another page or perform another action
  };

  return (
    <div className='search-tile flex flex-col min-h-screen'>
      <div className='flex-1'>
        {editMode ? (
          <div className="tile-content">
            <div className="tile-item">
              <label htmlFor="car-type">Car Type:</label>
              <select name="carType" value={formValues.carType} onChange={handleInputChange}>
                <option value="">Select Car Type</option>
                <option value="economy">Economy</option>
                <option value="luxury">Luxury</option>
                <option value="classic">Classic</option>
              </select>
            </div>
            <div className="tile-item">
              <label htmlFor="start-date">Start Date:</label>
              <input
                type="date"
                name="startDate"
                value={formValues.startDate}
                onChange={handleInputChange}
              />
            </div>
            <div className="tile-item">
              <label htmlFor="end-date">End Date:</label>
              <input
                type="date"
                name="endDate"
                value={formValues.endDate}
                onChange={handleInputChange}
              />
            </div>
            <div className="tile-item">
              <label htmlFor="start-time">Start Time:</label>
              <input
                type="time"
                name="startTime"
                value={formValues.startTime}
                onChange={handleInputChange}
              />
            </div>
            <div className="tile-item">
              <label htmlFor="end-time">End Time:</label>
              <input
                type="time"
                name="endTime"
                value={formValues.endTime}
                onChange={handleInputChange}
              />
            </div>
            <button className="save-button" onClick={handleSave}>Save</button>
          </div>
        ) : (
          <div className="tile-content">
            <div className="tile-item">
              <label>Car Type:</label>
              <p>{formValues.carType}</p>
            </div>
            <div className="tile-item">
              <label>Start Date:</label>
              <p>{formValues.startDate}</p>
            </div>
            <div className="tile-item">
              <label>End Date:</label>
              <p>{formValues.endDate}</p>
            </div>
            <div className="tile-item">
              <label>Start Time:</label>
              <p>{formValues.startTime}</p>
            </div>
            <div className="tile-item">
              <label>End Time:</label>
              <p>{formValues.endTime}</p>
            </div>
            <button className="edit-button" onClick={() => setEditMode(true)}>Edit</button>
          </div>
        )}
        <br />
        <h2>Select Car</h2>
        <br></br>
        {/* Cars Section */}
        <div className="lg:w-3/4 grid grid-cols-1 lg:grid-cols-3 gap-4">
          {filteredCars.map((car) => (
            <div key={car.id} className="border p-4">
              <h3 className="text-lg font-bold mb-2">{car.name}</h3>
              <img src={car.imageUrl} alt={car.name} className="mb-2 rounded-md" style={{ maxWidth: '100%' }} />
              <p className="text-gray-600 mb-2">{car.type}</p>
              <p className="mb-2">Price: ${car.price}</p>
              <p className="mb-2">Seats: {car.seats}</p>
              <div>
                <button
                  onClick={() => handleSelectCar(car)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                >
                  +
                </button>
                {selectedCars.find((item) => item.car._id === car._id) && (
                  <span>{selectedCars.find((item) => item.car._id === car._id).quantity}</span>
                )}
                <button
                  onClick={() => handleRemoveCar(car)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                >
                  -
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Conditional rendering of Select Location button */}
      {selectedCars.length > 0 && (
        <div className="fixed bottom-0 left-0 right-0 bg-gray-200 p-4 flex  justify-center">
          <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full lg:w-1/3"
            onClick={handleSelectLocation}
          >
            Select Location
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchCars;
