import React from 'react';
import Footer from '../components/Footer';
// import './EconomyPage.css';

const EconomyPage = () => {
  return (
    <>
      <div className="economy-container">
        <div className="section">
          <div className="text-image">
            <div className="text">
              <h2>Economy Car Options for Budget-Conscious Travelers</h2>
              <p>
                If you're a budget-conscious traveler looking for a cost-effective transportation solution, Gatwick Rent A Car offers a variety of economy car options that are both reliable and affordable.
              </p>
            </div>
            <div className="image">
              <img src="/economy/economy_selection.jpg" alt="Economy Car Options" />
            </div>
          </div>
        </div>

        <div className="section">
          <div className="text-image reverse">
            <div className="text">
              <h2>Compact Car Models</h2>
              <p>
                Their compact car models are ideal for navigating city streets with ease while saving on fuel costs. Perfect for solo travelers or small groups, these cars offer comfort and convenience without breaking the bank.
              </p>
            </div>
            <div className="image">
              <img src="/economy/compact_car.jpg" alt="Compact Car Models" />
            </div>
          </div>
        </div>

        <div className="section">
          <div className="text-image">
            <div className="text">
              <h2>Affordable Daily and Weekly Rates</h2>
              <p>
                Gatwick Rent A Car provides competitive daily and weekly rates for their economy car options, making it a practical choice for those looking to explore Dubai without overspending on transportation.
              </p>
            </div>
            <div className="image">
              <img src="/economy/daily_rates.jpg" alt="Daily and Weekly Rates" />
            </div>
          </div>
        </div>

        <div className="gallery">
          <h2>Image Gallery</h2>
          <div className="gallery-container">
            <img src="/economy/economy1.jpg" alt="Economy Car 1" className="gallery-item" />
            <img src="/economy/economy2.jpg" alt="Economy Car 2" className="gallery-item" />
            <img src="/economy/economy3.jpg" alt="Economy Car 3" className="gallery-item" />
            <img src="/economy/economy4.png" alt="Economy Car 4" className="gallery-item" />
            <img src="/economy/economy5.jpg" alt="Economy Car 5" className="gallery-item" />
            <img src="/economy/economy6.jpg" alt="Economy Car 6" className="gallery-item" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EconomyPage;
