import React from 'react';
import Footer from '../components/Footer';
// import './ContactPage.css';

const ContactPage = () => {
  return (
    <>
    <div className="contact-container">
      <h2>Contact Us</h2>
      <div className="contact-info">
        <div className="info-item">
          <i className="fas fa-map-marker-alt"></i>
          <p>United Arab Emirates, Dubai</p>
          <p>Dubai, Sheikh Rashid Road</p>
        </div>
        <div className="info-item">
          <i className="fas fa-phone-alt"></i>
          <div>
          <p>Phone: +971 544805580</p>
          <p>FAX: +971 42979745</p>
          </div>
        </div>
        <div className="info-item">
          <i className="fas fa-clock"></i>
          <div>
            
          <p>Time: 10:00AM – 10:00PM</p>
          <p>Open: We Open All Week</p>
          </div>
        </div>
      </div>
      <div className="contact-form">
        <h3>Contact Form</h3>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="5" required></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default ContactPage;
