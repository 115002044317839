// src/redux/actions/carsActions.js
export const ADD_CAR = 'ADD_CAR';
export const REMOVE_CAR = 'REMOVE_CAR';
export const UPDATE_CAR_QUANTITY = 'UPDATE_CAR_QUANTITY';

export const addCar = (car) => ({
  type: ADD_CAR,
  payload: car,
});

export const removeCar = (carId) => ({
  type: REMOVE_CAR,
  payload: carId,
});

export const updateCarQuantity = (carId, quantity) => ({
  type: UPDATE_CAR_QUANTITY,
  payload: { carId, quantity },
});
