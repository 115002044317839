import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../redux/userSlice';
import { Link, useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [step, setStep] = useState(1); // 1 for email form, 2 for OTP form
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        // Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const requestOtp = async () => {
        try {
            const response = await axios.post('https://car-backend-ecvl.onrender.com/request-otp', { email });
            setMessage(response.data.message);
            setStep(2);
        } catch (error) {
            console.error('Error requesting OTP:', error.response.data.message);
        }
    };

    const verifyOtp = async () => {
        const otpCode = otp.join('');
        try {
            const response = await axios.post('https://car-backend-ecvl.onrender.com/verify-otp', { email, otp: otpCode });
            dispatch(setUserInfo(response.data?.user));
            
            setMessage('Login successful');
            console.log('Login successful, token:', response.data.token, response.data?.user);
            navigate('/');
            // You can save the token or perform other actions here
        } catch (error) {
            console.error('Error verifying OTP:', error);
            setMessage('Invalid OTP');
        }
    };

    return (
        <div className="relative flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: `url('/background.jpg')` }}>
            <div className="absolute inset-0 overlay"></div> {/* Overlay effect */}
            <div className="relative bg-white p-6 rounded shadow-md w-full max-w-sm">
                {step === 1 && (
                    <>
                        <h2 className="text-2xl font-bold mb-4 text-center">Login</h2>
                        <form>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <div>
                                    <span className="text-sm">New user? </span>
                                    <Link to="/register" className="text-blue-500 hover:text-blue-700 text-sm">
                                        Register
                                    </Link>
                                </div>
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button"
                                    onClick={requestOtp}
                                >
                                    Send OTP
                                </button>
                            </div>
                        </form>
                    </>
                )}
                {step === 2 && (
                    <>
                        <h2 className="text-2xl font-bold mb-4 text-center">Enter OTP</h2>
                        <form>
                            <div className="mb-4 flex justify-center space-x-2">
                                {otp.map((data, index) => {
                                    return (
                                        <input
                                            className="shadow appearance-none border rounded w-12 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text"
                                            name="otp"
                                            maxLength="1"
                                            key={index}
                                            value={data}
                                            onChange={(e) => handleOtpChange(e.target, index)}
                                            onFocus={(e) => e.target.select()}
                                        />
                                    );
                                })}
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button"
                                    onClick={verifyOtp}
                                >
                                    Login
                                </button>
                            </div>
                        </form>
                    </>
                )}
                {message && <p className="text-red-500 text-xs italic mt-4">{message}</p>}
            </div>
        </div>
    );
};

export default LoginPage;
