import { useEffect } from 'react';

const useScrollAnimation = (ref) => {
  const observer = new IntersectionObserver(
    (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        ref.current.style.transition = 'all 0.5s ease-out';
        ref.current.style.opacity = '1';
        ref.current.style.transform = 'translateY(0)';
      }
    },
    { threshold: 0.5 }
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);
};

export default useScrollAnimation;