// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer, { initializeUserState } from './userSlice';
import searchReducer from './searchSlice';
import locationSlice from './locationSlice';
// import carsReducer from './reducer/carsReducer';

const store = configureStore({
  reducer: {
    user: userReducer,
    search: searchReducer,
    location: locationSlice
    // cars: carsReducer,

  },
});

store.dispatch(initializeUserState());

export default store;
