// src/components/Navbar.js
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { clearUserInfo } from '../redux/userSlice';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const userInfo = useSelector((state) => state.user.userInfo);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const dispatch = useDispatch();

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const getUserInitials = (name) => {
    if (!name) return ''; // Handle cases where name is undefined
    const initials = name.split(' ').map((n) => n[0]).join('');
    return initials.toUpperCase();
  };

  const handleLogout = () => {
    dispatch(clearUserInfo());
  };

  return (
    <>
      <div className={`navbar ${menuOpen ? 'menu-open' : ''}`}>
        <div className='menu-div-open'>
          <Link to="/" className="logo">
            GATWICK RENT A CAR
          </Link>

          <div className="hamburger" onClick={handleToggleMenu}>
            <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
            <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
            <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
            

          </div>
        </div>

        <div className={`center ${menuOpen ? 'menu-open' : ''}`}>
          <Link to="/ ">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/classic">Classic</Link>
          <Link to="/luxury">Luxury</Link>
          <Link to="/economy">Economy</Link>
          <Link to="/contact">Contact</Link>
          {isLoggedIn ? (
              <Link to="/bookings">Bookings</Link>):<></>} 

               
        </div>

        <div className={`right ${menuOpen ? 'menu-icon-open' : ''}`}>
          {isLoggedIn ? (
            <div className="flex items-center space-x-2">
              <div className="w-8 h-8 bg-blue-500 text-white rounded-full flex items-center justify-center">
                {getUserInitials(userInfo?.name)}
              </div>
              <button onClick={handleLogout} className="bg-red-500 text-white rounded-full px-3 py-1">
                Logout
              </button>
            </div>
          ) : (
            <>
              <Link to="/login">Login</Link>
              <span>|</span>
              <Link to="/register">Register</Link>
            </>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Navbar;
