import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { setSearchCriteria } from '../redux/searchSlice';
import { useNavigate } from 'react-router-dom';

const HeroTile = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [carType, setCarType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  useEffect(() => {
    const storedSearchCriteria = localStorage.getItem('searchCriteria');
    if (storedSearchCriteria) {
      const { carType, startDate, endDate, startTime, endTime } = JSON.parse(storedSearchCriteria);
      setCarType(carType);
      setStartDate(startDate);
      setEndDate(endDate);
      setStartTime(startTime);
      setEndTime(endTime);
    }
  }, []);

  const saveSearchCriteriaToLocalStorage = () => {
    const searchCriteria = { carType, startDate, endDate, startTime, endTime };
    localStorage.setItem('searchCriteria', JSON.stringify(searchCriteria));
  };

  const handleSearch = () => {
    if (!validateDates()) {
      alert('End date must be after start date');
      return;
    }
    if (!validateTimes()) {
      alert('End time must be after start time on the same date');
      return;
    }

    dispatch(setSearchCriteria({ carType, startDate, endDate, startTime, endTime }));

    saveSearchCriteriaToLocalStorage();

    onClose();
    navigate('/search-car');
  };

  const handleClose = () => {
    onClose();
  };

  const validateDates = () => {
    if (!startDate || !endDate) return true;
    return new Date(endDate) >= new Date(startDate);
  };

  const validateTimes = () => {
    if (!startTime || !endTime) return true;

    const startDateObj = new Date(`${startDate}T${startTime}`);
    const endDateObj = new Date(`${endDate}T${endTime}`);

    return endDateObj >= startDateObj;
  };

  return (
    <div className="tile">
      <div className="tile-content">
        <div className="tile-item">
          <label htmlFor="car-type">Car Type:</label>
          <select
            id="car-type"
            value={carType}
            onChange={(e) => setCarType(e.target.value)}
          >
            <option value="">Select Car Type</option>
            <option value="economy">Economy</option>
            <option value="luxury">Luxury</option>
            <option value="classic">Classic</option>
          </select>
        </div>
        <div className="tile-item">
          <label htmlFor="start-date">Start Date:</label>
          <input
            type="date"
            id="start-date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="tile-item">
          <label htmlFor="end-date">End Date:</label>
          <input
            type="date"
            id="end-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="tile-item">
          <label htmlFor="start-time">Start Time:</label>
          <input
            type="time"
            id="start-time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>
        <div className="tile-item">
          <label htmlFor="end-time">End Time:</label>
          <input
            type="time"
            id="end-time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
        <button className="search-button" onClick={handleSearch}>
          Search
        </button>
        <button className="close-button" onClick={handleClose}>
          <IoMdClose />
        </button>
      </div>
    </div>
  );
};

export default HeroTile;
