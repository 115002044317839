import React from 'react';
import Footer from '../components/Footer';
// import './ClassicPage.css';

const ClassicPage = () => {
  return (
    <>
    <div className="classic-container">
      <div className="section">
        <div className="text-image">
          <div className="text">
            <h2>Classic Car Selections for Timeless Elegance</h2>
            <p>
              Experience a touch of nostalgia and timeless elegance with Gatwick Rent A Car's classic car selections, perfect for special occasions and creating unforgettable memories.
            </p>
          </div>
          <div className="image">
            <img src="/classic/classic_selection.jpg" alt="Classic Car Selections" />
          </div>
        </div>
      </div>

      <div className="section">
        <div className="text-image reverse">
          <div className="text">
            <h2>Vintage and Iconic Car Models</h2>
            <p>
              Choose from a range of vintage and iconic car models that exude charm and sophistication. Whether you're a classic car enthusiast or simply appreciate timeless elegance, these vehicles are sure to turn heads wherever you go.
            </p>
          </div>
          <div className="image">
            <img src="/classic/vintage_cars.jpg" alt="Vintage and Iconic Car Models" />
          </div>
        </div>
      </div>

      <div className="section">
        <div className="text-image">
          <div className="text">
            <h2>Rental Packages for Special Occasions</h2>
            <p>
              Gatwick Rent A Car offers tailored rental packages for special occasions such as weddings, anniversaries, or milestone celebrations, birthdays, events, modeling, movie shooting, and taking photographs. Make a statement and arrive in style with a classic car that adds a touch of sophistication and glamour to your event.
            </p>
          </div>
          <div className="image">
            <img src="/classic/special_occasions.jpg" alt="Special Occasions" />
          </div>
        </div>
      </div>

      <div className="gallery">
        <h2>Image Gallery</h2>
        <div className="gallery-container">
          <img src="/classic/classic1.jpg" alt="Classic Car 1" className="gallery-item" />
          <img src="/classic/classic2.png" alt="Classic Car 2" className="gallery-item" />
          <img src="/classic/classic3.jpeg" alt="Classic Car 3" className="gallery-item" />
          <img src="/classic/classic4.jpg" alt="Classic Car 4" className="gallery-item" />
          <img src="/classic/classic5.jpg" alt="Classic Car 5" className="gallery-item" />
          <img src="/classic/classic6.jpeg" alt="Classic Car 6" className="gallery-item" />
        </div>
      </div>
    </div>
    <Footer />
    </>
    
  );
};

export default ClassicPage;
