// src/redux/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: null,
  isLoggedIn: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
      state.isLoggedIn = true;
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
    },
    clearUserInfo: (state) => {
      state.userInfo = null;
      state.isLoggedIn = false;
      localStorage.removeItem('userInfo');
    },
    initializeUserState: (state) => {
      const userInfo = localStorage.getItem('userInfo');
      if (userInfo) {
        try {
          state.userInfo = JSON.parse(userInfo);
          state.isLoggedIn = true;
        } catch (error) {
          console.error('Error parsing user info from localStorage:', error);
          localStorage.removeItem('userInfo'); // Clean up invalid data
        }
      }
    },
  },
});

export const { setUserInfo, clearUserInfo, initializeUserState } = userSlice.actions;
export default userSlice.reducer;
