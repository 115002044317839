import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const cars = [
  {
    name: 'panther kallista',
    description: 'A timeless classic with sleek curves and a powerful engine.',
    image: '/classic/1.jpeg'
  },
  {
    name: '1967 Austin FX4',
    description: 'An iconic muscle car known for its performance and style.',
    image: '/classic/2.jpeg'
  },
  {
    name: '1946 Dodge Deluxe',
    description: 'A symbol of European elegance and engineering excellence.',
    image: '/classic/3.jpeg'
  }
];

const FeaturedClassicCars = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <div className="featured-classic-cars">
      <h2>Featured Classic Cars</h2>
      <Slider {...settings}>
        {cars.map((car, index) => (
          <div key={index} className="car-slide">
            <img src={car.image} alt={car.name} className="car-image" />
            <div className="car-info">
              <h3>{car.name}</h3>
              <p>{car.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default FeaturedClassicCars;
