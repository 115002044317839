import React, { useState, useEffect } from 'react';
import { IoIosArrowForward } from "react-icons/io";
// import HeroTile from './HeroTile';
// import './HomeHeroScrol.css';


const HomeHeroScrol = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    {
      id: 0,
      url: '/hero/img4.jpg',
      text: 'Experience Unmatched Luxury and Comfort',
    },
    // {
    //   id: 1,
    //   url: '/hero/img1.jpg',
    //   text: 'Drive the Past, Embrace the Elegance',
    // },
    {
      id: 1,
      url: '/hero/img6.jpeg',
      text: 'Affordable Rides, Anytime, Anywhere',
    },
    {
      id: 2,
      url: '/hero/img5.jpg',
      text: 'Affordable Rides, Anytime, Anywhere',
    },
    // {
    //   id: 3,
    //   url: '/hero/img3.jpg',
    //   text: 'Affordable Rides, Anytime, Anywhere',
    // },
    // {
    //   id: 4,
    //   url: '/hero/img2.jpg',
    //   text: 'Affordable Rides, Anytime, Anywhere',
    // },
    
    {
      id: 3,
      url: '/hero/img7.jpg',
      text: 'Affordable Rides, Anytime, Anywhere',
    },
    {
      id: 4,
      url: '/hero/img8.jpg',
      text: 'Affordable Rides, Anytime, Anywhere',
    },
    {
      id: 5,
      url: '/hero/img10.jpg',
      text: 'Affordable Rides, Anytime, Anywhere',
    },
    
  ];

  const handleNextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(handleNextImage, 3000); // Change image every 3 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [currentImage]);

  return (
    <div className="slider">
      <div className="slider-inner">
        {images.map((image) => (
          <div
            key={image.id}
            className={currentImage === image.id ? 'slide active' : 'slide'}
            style={{ backgroundImage: `url(${image.url})` }}
          >
            {/* <div className="slide-content">
              <h2>{image.text}</h2>
            </div> */}
          </div>
        ))}
      </div>
      {/* <HeroTile /> */}
      <button className="next-button" onClick={handleNextImage}>
      <IoIosArrowForward className='right-icon'/>
      </button>
    </div>
  );
};

export default HomeHeroScrol;

