import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
// import './AboutUs.css'; // Make sure to import your CSS file

const AboutUs = () => {
  return (
    <>
      <div className="about-container">
        <section className="section history">
          <h2>History and Background of Gatwick Rent A Car</h2>
          <p>With a solid reputation for providing top-notch rental services, Gatwick Rent A Car has been a trusted choice for both locals and tourists in Dubai. Their commitment to customer satisfaction and quality vehicles has made them a go-to option for all car rental needs.</p>
        </section>

        <section className="section services">
          <h2>Our Services</h2>
          <div className="card-container">
            <Link to="/economy" className="card">
              <img src="/about/economy.jpg" alt="Economy Cars" />
              <h3>Economy Car Options</h3>
              <p>Perfect for solo travelers or small groups, these cars offer comfort and convenience without breaking the bank.</p>
            </Link>
            <Link to="/luxury" className="card">
              <img src="/about/luxury.jpg" alt="Luxury Cars" />
              <h3>Luxury Car Rentals</h3>
              <p>Indulge in luxury with high-end car brands such as Mercedes-Benz, BMW, and Audi, among others.</p>
            </Link>
            <Link to="/classic" className="card">
              <img src="/about/classic.jpeg" alt="Classic Cars" />
              <h3>Classic Car Selections</h3>
              <p>Experience a touch of nostalgia and timeless elegance with our classic car selections.</p>
            </Link>
          </div>
        </section>

        <section className="section summary">
          <h2>Summary</h2>
          <p>Gatwick Rent A Car in Dubai offers a seamless and comprehensive car rental experience, ensuring that every traveler finds the perfect vehicle for their journey through the bustling streets of this cosmopolitan city.</p>
        </section>

        <section className="section contact">
          <h2>Contact Us</h2>
          <p>For any inquiries or to book a car, please contact us at: info@gatwickrac.ae / support@gatwickrac.ae or call us at: 042979745.</p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
