import React from 'react'
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoMdPin } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { FaCheck } from 'react-icons/fa';



const Footer = () => {
    return (
        <footer>
            <div className='contact-card'>

                <div id="contact" className='con-card-div'>
                    <div className='con-card-div-icon'><FaPhoneAlt /></div>
                    <div>
                        <h5>Call us</h5>
                        <p>Gatwick Rant Car Office: 042979745</p>
                        <p>Connect with us : 0544805580</p>
                    </div>

                </div>

                <div className='con-card-div'>
                    <div className='con-card-div-icon'><MdEmail /></div>
                    <div>
                        <h5>Write to us</h5>
                        <p>info@gatwickrac.ae</p>
                        <p>support@gatwickrac.ae</p>
                    </div>
                </div>

                <div className='con-card-div'>
                    <div className='con-card-div-icon'><MdEmail /></div>
                    <div>
                        <h5>SERVICE HOURS</h5>
                        <p>All Week: 10:00AM - 10:00PM</p>
                    </div>
                </div>

                <div className='con-card-div'>
                    <div className='con-card-div-icon'><IoMdPin /></div>
                    <div>
                        <h5>Address</h5>
                        <p>Dubai, SHEIKH RASHID ROAD</p>
                    </div>
                </div>

            </div>

            <div className='footer-card'>
                <div className='logo-div'>
                    <div className='logo'>
                        Gatwick Rent A Car
                    </div>
                    <p>
                    Get the best offers for economy, luxury/ sports car rental, chauffeur service and chauffeur rental service. Our headquarters are located in Dubai, and our services are available in selected cities around the world.
                    </p>
                    <div className='social-icon-div'>
                        <div className='social-icon'>
                            <IoLogoWhatsapp />
                        </div>
                        <div className='social-icon'>
                            <a href="https://www.instagram.com/gatwickrental?igsh=MW84cXZkdzY4aWRjeA==" target="_blank">

                            <FaInstagram />
                            </a>
                        </div>
                        <div className='social-icon'>
                            <a href='https://www.facebook.com/people/Gatwick-Rental-Car/61561154772649/' target='_blank'>

                            <FaFacebookF />
                            </a>
                        </div>

                    </div>
                </div>

                <div className='quick-links-div'>
                    <h4>Quick links</h4>
                    <ul>
                        <li>Home</li>
                        <li>About</li>
                        <li>Services</li>
                        <li>Profile</li>
                        <li>Career</li>
                    </ul>
                </div>

                <div className='subscribe-div'>
                    <h4>Subscribe</h4>
                    <p>Want to be notified about our services. Just sign up and we'll send you a notification by email.</p>
                    <div className="input-container">
                        <input type="text" placeholder="Email address" className="input-box" />
                        <button className="input-button">
                            <FaCheck className="button-icon" />
                        </button>
                    </div>
                </div>
            </div>

            <div className='copyright'>
            @2024 All rights reserved by Gatwickc
            </div>
        </footer>
    )
}

export default Footer