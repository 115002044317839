import React from 'react';
import { FaCheck } from "react-icons/fa";
import { MdArrowOutward } from "react-icons/md";


const Section2 = () => {
    return (
        <div className='section-2' >
            <div className="left">
                <h5 className='section-2-h5'>Gatwick Rent A Car</h5>
                <h1 className='section-2-h1'>We Are More Than<br />
                    A Car Rental Company</h1>
                <p className='section-2-p'> Gatwick Rent A Car presents an extraordinary array of car rental options in the vibrant and dynamic city of Dubai, catering to travellers' diverse needs and preferences. Whether you seek a budget-friendly option for your journey, aspire to cruise in luxurious comfort, or desire the timeless elegance of a classic car, Gatwick Rent A Car has you covered. Discover Dubai in grand style with its extensive fleet, featuring competitive rates and exceptional customer service for an unparalleled rental experience.</p>
                <h3 className='section-2-h3'>Our Services Include</h3>
                <p className='section-2-p2'>Our services cater to a wide range of needs in Dubai, whether you're looking for an economical, luxury, or sports car. Experience unparalleled convenience and quality with Gatwick Rent A Car.</p>
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}>
                        <div className='check-circle'>
                            <FaCheck />
                        </div>
                        Flexible Rental Periods</div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}><div className='check-circle'>
                        <FaCheck />
                    </div> Chauffeur Service</div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}><div className='check-circle'>
                        <FaCheck />
                    </div> Airport and Emirate-Wide Pickup</div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}><div className='check-circle'>
                        <FaCheck />
                    </div> Easy Rental Process</div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}><div className='check-circle'>
                        <FaCheck />
                    </div> Diverse Vehicle Selection</div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}><div className='check-circle'>
                        <FaCheck />
                    </div> Prompt Customer Support</div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}><div className='check-circle'>
                        <FaCheck />
                    </div> Accident Forgiveness</div>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}><div className='check-circle'>
                        <FaCheck />
                    </div> High-Quality Modern Fleet                    </div>
            
                </div>
                <button className='read-more-btn'>Read more <MdArrowOutward /></button>
            </div>
            <img className='right'
                src="/section-2.jpg"
                alt="image"
            />
        </div>
    )
}

export default Section2