import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchCriteria: {
    carType: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: ''
  },
  selectedCars: []
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchCriteria: (state, action) => {
      state.searchCriteria = action.payload;
    },
    setInitialSearchCriteria: (state, action) => {
      state.searchCriteria = { ...state.searchCriteria, ...action.payload };
    },
    clearSearchCriteria: (state) => {
      state.searchCriteria = initialState.searchCriteria;
    },
    setSelectedCars: (state, action) => {
      state.selectedCars = action.payload;
      localStorage.setItem('selectedCars', JSON.stringify(action.payload));
    },
    initializeSelectedCars: (state) => {
      const storedSelectedCars = localStorage.getItem('selectedCars');
      if (storedSelectedCars) {
        try {
          state.selectedCars = JSON.parse(storedSelectedCars);
        } catch (error) {
          console.error('Error parsing stored selected cars:', error);
        }
      }
    },
    addSelectedCar: (state, action) => {
      const { car, quantity } = action.payload;
      const existingCar = state.selectedCars.find(item => item.car._id === car._id);

      if (existingCar) {
        existingCar.quantity += quantity;
      } else {
        state.selectedCars.push({ car: car, quantity: 1 });
      }
    },
    removeSelectedCar: (state, action) => {
      const carIdToRemove = action.payload;
      const existingCar = state.selectedCars.find(item => item.car._id === carIdToRemove);

      if (existingCar) {
        existingCar.quantity -= 1;
        if (existingCar.quantity === 0) {
          state.selectedCars = state.selectedCars.filter(item => item.car._id !== carIdToRemove);
        }
      }
    },
    clearSelectedCars: (state) => {
      state.selectedCars = [];
    }
  }
});

export const {
  setSearchCriteria,
  setInitialSearchCriteria,
  clearSearchCriteria,
  setSelectedCars,
  addSelectedCar,
  removeSelectedCar,
  clearSelectedCars
} = searchSlice.actions;

export const selectSearchCriteria = (state) => state.search.searchCriteria;
export const selectSelectedCars = (state) => state.search.selectedCars;

export default searchSlice.reducer;
