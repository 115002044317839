import React, { useState } from 'react';

const faqs = [
    {
        question: "Can I choose the specific classic car model for my rental in Dubai?",
        answer: "Our return policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately, we can’t offer you a refund or exchange."
    },
    {
        question: "What are the typical hourly rental rates for chauffeur-driven classic cars in Dubai?",
        answer: "Once your order has been dispatched, we will send you an email with your tracking information."
    },
    {
        question: "Are the chauffeurs well-trained and knowledgeable about the history of the classic cars they drive? ",
        answer: "Yes, we ship internationally. Shipping charges and available service will vary based on the package weight and country of destination."
    },
    {
        question: "Is it possible to customize the experience with additional services or decorations for special events when renting a classic car in Dubai?",
        answer: "Once your order has been dispatched, we will send you an email with your tracking information."
    }
];

const FaqSection = () => {
    const [expanded, setExpanded] = useState(null);

    const toggleExpand = index => {
        setExpanded(expanded === index ? null : index);
    };

    return (
        <div className="faq-section">
            <h2>Frequently Asked Questions</h2>
            <div className="faqs">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <div className="faq-question" onClick={() => toggleExpand(index)}>
                            {faq.question}
                        </div>
                        <div className={`faq-answer ${expanded === index ? 'show' : ''}`}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FaqSection;
