import React from 'react';
import Footer from '../components/Footer';

const LuxuryPage = () => {
  return (
    <>
      <div className="classic-container">
        <div className="section">
          <div className="text-image">
            <div className="text">
              <h2>Luxury Car Rentals for a Premium Experience</h2>
              <p>
                For those seeking a premium and sophisticated driving experience in Dubai, Gatwick Rent A Car offers a selection of luxury car rentals that are sure to impress.
              </p>
            </div>
            <div className="image">
              <img src="/luxury/luxury_rental.jpg" alt="Luxury Car Rentals" />
            </div>
          </div>
        </div>

        <div className="section">
          <div className="text-image reverse">
            <div className="text">
              <h2>High-End Car Brands Offered</h2>
              <p>
                Indulge in luxury with high-end car brands such as Mercedes-Benz, BMW, and Audi, among others. Experience the thrill of driving a top-of-the-line vehicle while exploring the vibrant city of Dubai in style.
              </p>
            </div>
            <div className="image">
              <img src="/luxury/high_end_brands.jpg" alt="High-End Car Brands" />
            </div>
          </div>
        </div>

        <div className="section">
          <div className="text-image">
            <div className="text">
              <h2>Exclusive Features and Amenities</h2>
              <p>
                Enjoy a host of exclusive features and amenities in Gatwick Rent A Car's luxury vehicles, including plush interiors, advanced technology, and superior performance. Elevate your travel experience with a touch of sophistication and opulence.
              </p>
            </div>
            <div className="image">
              <img src="/luxury/exclusive_features.jpg" alt="Exclusive Features and Amenities" />
            </div>
          </div>
        </div>

        <div className="gallery">
          <h2>Image Gallery</h2>
          <div className="gallery-container">
            <img src="/luxury/luxury1.jpg" alt="Luxury Car 1" className="gallery-item" />
            <img src="/luxury/luxury2.jpg" alt="Luxury Car 2" className="gallery-item" />
            <img src="/luxury/luxury3.jpg" alt="Luxury Car 3" className="gallery-item" />
            <img src="/luxury/luxury4.jpg" alt="Luxury Car 4" className="gallery-item" />
            <img src="/luxury/luxury5.jpg" alt="Luxury Car 5" className="gallery-item" />
            <img src="/luxury/luxury6.jpg" alt="Luxury Car 6" className="gallery-item" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LuxuryPage;
