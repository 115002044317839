// src/components/CarsPage.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { addCar, updateCarQuantity } from '../redux/actions/carsActions';

const CarsPage = () => {
  const [cars, setCars] = useState([]);
  const [filters, setFilters] = useState({
    type: '', // Possible values: 'suv', 'economy', 'luxury'
  });
  const dispatch = useDispatch();
  const selectedCars = useSelector((state) => state.cars.selectedCars);

  useEffect(() => {
    fetchCars();
  }, []);

  const fetchCars = async () => {
    try {
      const response = await axios.get('https://car-backend-ecvl.onrender.com/cars'); // Replace with your backend API endpoint
      setCars(response.data);
    } catch (error) {
      console.error('Error fetching cars:', error);
    }
  };

  const applyFilters = () => {
    let filteredCars = [...cars];

    // Apply filter based on car type (SUV, Economy, Luxury)
    if (filters.type) {
      filteredCars = filteredCars.filter((car) => car.type === filters.type);
    }

    // Update state with filtered cars
    setCars(filteredCars);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectCar = (car) => {
    const foundCar = selectedCars.find((item) => item.car.id === car.id);
    if (foundCar) {
      const newQuantity = foundCar.quantity + 1;
      dispatch(updateCarQuantity(car.id, newQuantity));
      localStorage.setItem('selectedCars', JSON.stringify(selectedCars));
    } else {
      dispatch(addCar(car));
      localStorage.setItem('selectedCars', JSON.stringify([...selectedCars, { car, quantity: 1 }]));
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col lg:flex-row gap-4">
        {/* Filter Section (Left for large screens, Top for small screens) */}
        <div className="lg:w-1/4 mb-4 lg:mb-0">
          <h2 className="text-lg font-bold mb-2">Filters</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Car Type</label>
            <select
              name="type"
              value={filters.type}
              onChange={handleFilterChange}
              className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">All</option>
              <option value="suv">SUV</option>
              <option value="economy">Economy</option>
              <option value="luxury">Luxury</option>
            </select>
          </div>
          <button
            onClick={applyFilters}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Apply Filters
          </button>
        </div>

        {/* Cars Section */}
        <div className="lg:w-3/4 grid grid-cols-1 lg:grid-cols-3 gap-4">
          {cars.map((car) => (
            <div key={car.id} className="border p-4">
              <h3 className="text-lg font-bold mb-2">{car.name}</h3>
              <p className="text-gray-600 mb-2">{car.type}</p>
              <p className="mb-2">Price: ${car.price}</p>
              <p className="mb-2">Seats: {car.seats}</p>
              <button
                onClick={() => handleSelectCar(car)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Select Car
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarsPage;
