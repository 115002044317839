// src/redux/slices/locationSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pickup: {
    coordinates: null,
    address: '',
  },
  dropoff: {
    coordinates: null,
    address: '',
  },
  distance: null,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocations: (state, action) => {
      const { pickup, dropoff, distance } = action.payload;
      state.pickup.coordinates = pickup.coordinates;
      state.pickup.address = pickup.address;
      state.dropoff.coordinates = dropoff.coordinates;
      state.dropoff.address = dropoff.address;
      state.distance = distance;
    },
    unsetLocations: (state) => {
      state.pickup.coordinates = null;
      state.pickup.address = '';
      state.dropoff.coordinates = null;
      state.dropoff.address = '';
      state.distance = null;
    },
  },
});

export const { setLocations, unsetLocations } = locationSlice.actions;
export default locationSlice.reducer;
