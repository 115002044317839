import React, { useRef, useState } from 'react'
import HomeHeroScrol from '../components/HomeHeroScrol'
// import StepInfo from '../components/StepInfo'
import HeroTile from '../components/HeroTile';
import useScrollAnimation from '../useScrollAnimation';
import Section2 from '../components/Section2';
import Footer from '../components/Footer';
import FaqSection from '../components/FaqSection';
import FeaturedClassicCars from '../components/FeaturedClassicCars';
import WhyChooseUs from '../components/WhyChooseUs';

const Home = () => {

  const [isHeroTileOpen, setIsHeroTileOpen] = useState(false);

  const handleOpenHeroTile = () => {
    setIsHeroTileOpen(true);
  };

  const handleCloseHeroTile = () => {
    setIsHeroTileOpen(false);
  };

  const sectionRef = useRef(null);
  const sectionRef1 = useRef(null);

  useScrollAnimation(sectionRef);
  useScrollAnimation(sectionRef1);



  return (
    <div>
      {/* {loading ? <LoadingPage /> : <></>} */}


      <HomeHeroScrol />

      {/* <StepInfo/> */}
      <section ref={sectionRef} className="section section--animate">
        <Section2 />
      </section>
      <section ref={sectionRef1} className="section section--animate">
      <WhyChooseUs/>

      </section>
      <FeaturedClassicCars />
<FaqSection/>

<Footer />
      {isHeroTileOpen && <HeroTile onClose={handleCloseHeroTile} />}
      <button className="open-button" onClick={handleOpenHeroTile}>
        Book Now
      </button>
    </div>
  )
}

export default Home