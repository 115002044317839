import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router

const BookingsPage = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const [bookings, setBookings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`https://car-backend-ecvl.onrender.com/booking/api/bookings/user/${userInfo._id}`);
        setBookings(response.data.bookings);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    if (userInfo && userInfo._id) {
      fetchBookings();
    }
  }, [userInfo]);

  const handleBack = () => {
    navigate('/summary'); // Navigate back to summary page after viewing bookings
  };

  return (
    <div className="bookings-page">
      <h1>My Bookings</h1>
      {/* <button onClick={handleBack} className="back-button">Back to Summary</button> */}

      <div className="bookings-list">
        {bookings.length > 0 ? (
          <ul className="booking-items">
            {bookings.map((booking) => (
              <li key={booking._id} className="booking-item">
                <div className="booking-header">
                  <h3>Booking ID: {booking._id}</h3>
                  <p>Booking Date: {new Date(booking.bookingDateTime).toLocaleDateString()}</p>
                </div>
                <div className="booking-details">
                  <div className="booking-image">
                    <img src={booking.selectedCars[0].car.imageUrl} alt={booking.selectedCars[0].car.name} />
                  </div>
                  <div className="booking-info">
                    <p><strong>Pickup Location:</strong> {booking.pickupLocation.address}</p>
                    <p><strong>Dropoff Location:</strong> {booking.dropoffLocation.address}</p>
                    {/* Add more details as needed */}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No bookings found.</p>
        )}
      </div>
    </div>
  );
};

export default BookingsPage;
