// App.jsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
// import About from './components/About';
// import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Loading from './pages/Loading';
import { LoadingProvider, useLoading } from './LoadingContext';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import CarsPage from './pages/CarsPage';
import SearchCars from './pages/SearchCars';
// import RoutePage from './pages/RoutePage';
import MapSelection from './components/MapSelection';
import SummaryPage from './pages/SummaryPage';
import BookingsPage from './pages/BookingsPage';
import ClassicPage from './pages/ClassicPage';
import LuxuryPage from './pages/LuxuryPage';
import EconomyPage from './pages/EconomyPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';

const AppContent = () => {
  const location = useLocation();
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    const handleRouteChange = () => {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1500); // Adjust the delay as needed
    };

    handleRouteChange();
  }, [location, setIsLoading]);

  if (isLoading) {
    return <Loading />;
  }
  // const apiKey = 'AIzaSyC2FlUCcKISyKpukAc0noyLsd1mCJu4Ld0'; // Replace with your actual API key
  return (
    <>
      {/* <Navbar /> */}
      <Routes>
        <Route path='/' exact element={<Navbar/>}>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/classic' element={<ClassicPage />} />
        <Route path='/luxury' element={<LuxuryPage />} />
        <Route path='/economy' element={<EconomyPage />} />
        <Route path='/cars' element={<CarsPage/>}/>
        <Route path='/search-car' element={<SearchCars />} />
        {/* <Route path='/route-page' apiKey={apiKey}element={<RoutePage />} /> */}
        <Route path="/maps/select-location" element={<MapSelection mode="pickup" />} />
        <Route path="/booking-summary" element={<SummaryPage />} />
        <Route path='/bookings' element={<BookingsPage />}/>
        </Route>
        <Route path='/login' element={<LoginPage />}/>
        <Route path='/register' element={<RegisterPage />}/>

        {/* <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} /> */}
      </Routes>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <LoadingProvider>
        <AppContent />
      </LoadingProvider>
    </Router>
  );
};

export default App;